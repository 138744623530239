import React from "react"
import { Link } from "gatsby"
import NavBar from "../components/NavBar"
import Footer from "../components/Footer"
import Metatags from "../components/metatags"
import UseSiteMetadata from "../components/UseSiteMetadata"

const IndexEN = ({ pageContext, location }) => {
  console.log(location)
  const { siteUrl } = UseSiteMetadata()
  const  fullUrl = `${siteUrl}${location.pathname}`
  const schemaData = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@context": "https://schema.org",
        "@type": "Organization",
        email: "info@netbistrot.com",
        name: "NetBistrot",
        logo:"https://netbistrot.com/netbistrot-icon-540.png",
      },
      {
        "@type": "WebSite",
        "@id": `https://netbistrot.com${location.pathname}`,
        url: `https://netbistrotcom${location.pathname}`,
        name: "NetBistrot",
        inLanguage: "en-US",
      },
    ],
  }
  return (
    <>
      <Metatags
        title="NetBistrot - Digital agency | about us"
        description="We like to think about ourselves as a small, precise and careful business."
        url={fullUrl}
        image="https://netbistrot.com/netbistrot-icon-540.png"
        keywords="websites, web design, web app, progressive web application, pwa, social media, graphic design, graphic design studio"
        language={pageContext.langKey}
        schemaData={schemaData}
      />
      <NavBar />
      <div className="h-10" />
      <div className="container mx-auto">
        <div className=" mx-2 md:mx-0">
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
            <div className="col-span-2" />
            <div className="col-span-10">
              <h1>About us</h1>
            </div>
          </div>
          <div className="h-8" />
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
            <div className="col-span-4" />
            <div className="col-span-6">
              <span className="text-lg">
                We like to think about ourselves as a small, precise and careful
                business. Just like a bistrot we are always seeking to improve
                our efficiency.
                <br />
                <br />
                We conduct our business ethically, and we are concerned about
                our privacy, the privacy of our customers, and the users of our
                products.
                <br />
                <br />
                We put the utmost effort and care in every detail of every
                project we work on, and we provide our customers with the same
                treatment we would like to receive.
                <br />
                <br />
                We are constantly expanding our skills and searching for new
                tools to give the best answer for every demand.
                <br />
                <br />
                And of course we enjoy it.
                <br />
                <br />
                We are a Bistrot.
                <br />
                <br />
                &nbsp;
                <br />
                <br />
                Be welcome!
              </span>
              <div className="h-16" />
              <div className="text-center">
                <Link
                  className="btn-icon rounded text-lg text-white bg-blue-600 hover:bg-blue-800 focus:outline-none focus:ring px-4 py-2 font-semibold"
                  to="/en/lets-work-together/"
                >
                  Ready to work together?
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="h-10" />
      <Footer lang="en" />
    </>
  )
}

export default IndexEN
